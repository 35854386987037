.animated {
  transition: background-color 0.3s;
}

.white {
  background-color: $white;
}

.pink {
  background-color: $g-pink;
}

.red {
  color: $white;
  background-color: $g-red;
}

.alert {
  color: $textPrimary;
  background-color: $alert30;
}

.grey {
  background-color: $g-greyR2;
}

.dark {
  color: $white;
  background-color: $g-greyRey;
}

.extra {
  padding: 80px 0;

  @media ($smDown) {
    padding: 40px 0;
  }
}

.padding {
  padding: 50px 0;

  @media ($smDown) {
    padding: 30px 0;
  }
}

.tight {
  padding: 30px 0;

  @media ($smDown) {
    padding: 15px 0;
  }
}

.none {
  padding: 0;
}

.top {
  padding-top: var(--message-banner-height);
  transition: padding-top 0.5s ease-in-out;
}
