.hero {
  text-align: left;

  > * + * {
    margin-top: 36px;

    @media ($xsDown) {
      margin-top: 15px;
    }
  }
}

.heroSubHeading {
  max-width: 700px;
}

.searchTrip {
  margin-top: 40px;
}

.grid {
  $spacing: 15px;
  display: flex;
  flex-wrap: wrap;
  margin: calc($spacing / -2);

  > * {
    width: 33.33%;
    padding: calc($spacing / 2);

    @media ($xsDown) {
      width: 100%;
    }
  }
}

.pushContainer {
  display: flex;
  flex-direction: row;

  & > * {
    flex-grow: 0;
    width: 50%;

    &:first-child {
      margin-right: 10px;

      @media ($smDown) {
        margin-right: 0;
      }
    }

    &:not(:first-child) {
      margin-left: 10px;

      @media ($smDown) {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }

  @media ($smDown) {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }
}

.BookingDisabledInfo {
  padding: 20px 30px;
  color: $white;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}
