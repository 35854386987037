.root {
  font-size: 16px;

  > * {
    margin-top: 30px;
  }
}

.pictograms {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ($smDown) {
    flex-wrap: wrap;
    margin-left: 0;
  }

  @media ($xsDown) {
    flex-direction: column;
    margin: 0;
    margin-bottom: 20px;
  }
}

.pictoWrapper {
  display: flex;
  flex-basis: 33.33%;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  text-align: center;

  > * {
    width: 100%;
  }

  > span {
    display: flex;
    justify-content: center;
  }

  a {
    text-align: center;
  }

  @media ($smDown) {
    flex-basis: 50%;
    padding: 15px;
  }
}

.svg {
  height: 110px;
  margin-bottom: 10px;
  color: $secondary10;
}
