.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 80px 0;

  > * + * {
    margin-left: 64px;
  }

  &.reverse {
    > * + * {
      margin-right: 0;
      margin-left: 0;
    }
    flex-direction: row-reverse;
  }

  & > * {
    flex-basis: calc(50% - 15px);
  }

  @media ($smDown) {
    flex-direction: column;
    padding: 30px 5px;

    &.reverse {
      flex-direction: column;
    }

    > * + * {
      margin: 0;
    }
  }
}

.imageContainer {
  flex-grow: 0;
  height: 100%;

  @media ($smDown) {
    width: 100%;
  }

  img {
    width: 100%;
    border-radius: 3px;
  }
}

.content {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 30px 0;

  & > * + * {
    margin-top: 20px;
  }

  @media ($smDown) {
    padding-bottom: 0;
  }
}
