$icon-size: 20px;
$padding-y: 16px;
$padding-x: 20px;
$padding-small: 15px;

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  width: 100%;
}

.detached {
  max-width: 1296px;
  margin: 16px auto;
  border-radius: 4px;
}

.overlay {
  position: absolute;
  animation: fade-in 0.5s ease;
}

.root {
  position: relative;
  max-width: 1296px;
  padding: $padding-y $padding-x;
  margin: 0 auto;
  font-size: 16px;
  border-radius: 0;

  @media ($smDown) {
    padding: $padding-small;
    margin: 8px auto;
  }

  &.general {
    margin: 16px auto;

    svg {
      color: $white;
    }
  }

  &.trips {
    padding: 24px 32px;

    svg {
      color: $white;
    }
  }

  &.departure {
    svg {
      color: $white;
    }
  }

  &.detached {
    padding: $padding-x 40px;

    svg {
      color: $white;
    }
  }

  &.alteration {
    padding: 24px 32px;

    svg {
      color: $white;
    }
  }

  &.planning {
    svg {
      color: $white;
    }
  }

  &.corporate {
    padding: $padding-y 20px;

    svg {
      color: $white;
    }
  }
}

.message {
  display: flex;
  flex-direction: column;
}

.infoIcon {
  composes: icon from "../../main/css/icon.module.css";
  font-size: $icon-size;
  fill: none;
}

.closeIcon {
  composes: icon from "../../main/css/icon.module.css";
  font-size: 12px;

  path {
    stroke: $white;
  }
}

.header {
  display: flex;
}

.heading {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  > * + * {
    margin-left: 10px;
  }
}

.closeButton {
  display: flex;
  align-items: top;
  justify-content: center;
  margin-left: auto;

  svg {
    width: 12px;
    height: 12px;
    color: $textPrimary;
  }
}

.content {
  display: block;
  gap: 8px;
  width: 100%;
}
