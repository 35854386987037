.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px 60px;
  overflow: hidden;
  color: $white;
  background: $primary20;
  border-radius: 3px;

  @media ($xsDown) {
    padding: 25px 30px;
  }
}

.withImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.content {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  padding: 30px 0;

  & > * + * {
    margin-top: 20px;
  }
}
