.xxxl {
  font-size: 96px;
  font-weight: $g-bold;
  line-height: 96px;

  @media ($smDown) {
    font-size: 54px;
    line-height: 56px;
  }
}

.title1 {
  font-size: 54px;
  font-weight: $g-bold;
  line-height: 56px;

  @media ($smDown) {
    font-size: 42px;
    line-height: 48px;
  }
}

.title2 {
  font-size: 42px;
  font-weight: $g-bold;
  line-height: 48px;

  @media ($smDown) {
    font-size: 32px;
    line-height: 40px;
  }
}

.title3 {
  font-size: 32px;
  font-weight: $g-bold;
  line-height: 40px;

  @media ($smDown) {
    font-size: 24px;
    line-height: 32px;
  }
}

.title4 {
  font-size: 24px;
  font-weight: $g-bold;
  line-height: 32px;
}

.title5 {
  font-size: 20px;
  font-weight: $g-bold;
  line-height: 28px;
}

.center {
  text-align: center;
}

.upperCase {
  text-transform: uppercase;
}

.black,
.black span {
  color: $textPrimary;
}

.white,
.white span {
  color: $white;
}

.primary,
.primary span {
  color: $primary10;
}

.secondary,
.secondary span {
  color: $secondary15;
}

.textPrimary,
.textPrimary span {
  color: $textPrimary;
}

.textSecondary,
.textSecondary span {
  color: $textSecondary;
}

.disabled,
.disabled span {
  color: $textDisabled;
}

.inherit {
  color: inherit;
}

.fontAlternate {
  font-family: $g-fontFamilyAlternate;
}

.fontPrimary {
  font-family: $g-fontFamily;
}

.gutter {
  &Sm {
    margin-bottom: 0.5em;
  }

  &Md {
    margin-bottom: 1em;
  }

  * ~ & {
    margin-top: 2em;
  }
}
