$icon-size: 20px;
$padding-y: 16px;
$padding-x: 20px;
$padding-small: 15px;

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  z-index: $g-z-index-messageBanner;
  width: 100%;

  @media ($smDown) {
    padding: 0 8px;
  }
}

.overlay {
  position: absolute;
  animation: fade-in 0.5s ease;
}

.information {
  color: $white;
  background-color: $secondary20;
}

.important {
  color: $textPrimary;
  background-color: $alert30;

  svg {
    color: $textPrimary;

    path {
      stroke: $textPrimary;
    }
  }
}

.critical {
  color: $white;
  background-color: $error10;
}

.root {
  position: relative;
  max-width: 1296px;
  padding: $padding-y $padding-x;
  margin: 0 auto;
  font-size: 16px;
  border-radius: 0;

  @media ($smDown) {
    padding: $padding-small;
    margin: 8px auto;
  }

  &.general {
    margin: 16px auto;
    border-radius: 4px;

    svg {
      color: $white;
    }
  }

  &.trips {
    padding: 24px 32px;
    border-radius: 0;

    svg {
      color: $white;
    }
  }

  &.departure {
    svg {
      color: $white;
    }
  }

  &.detached {
    padding: $padding-x 40px;

    svg {
      color: $white;
    }
  }

  &.alteration {
    padding: 24px 32px;
    border-radius: 0;

    svg {
      color: $white;
    }
  }

  &.planning {
    svg {
      color: $white;
    }
  }

  &.corporate {
    padding: $padding-y 20px;
    background-color: $g-pink;

    svg {
      color: $white;
    }
  }
}

.message {
  display: flex;
  flex-direction: column;
}

.infoIcon {
  composes: icon from "../../main/css/icon.module.css";
  font-size: $icon-size;
  fill: none;
}

.closeIcon {
  composes: icon from "../../main/css/icon.module.css";
  font-size: 12px;
}

.header {
  display: flex;
}

.heading {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  > * + * {
    margin-left: 10px;
  }
}

.closeButton {
  display: flex;
  align-items: top;
  justify-content: center;
  margin-left: auto;

  svg {
    width: 12px;
    height: 12px;
    color: $textPrimary;
  }
}

.content {
  display: flex;
  gap: 8px;
  width: 100%;
}

.showMoreButton {
  font-family: $g-fontFamily;
  font-size: 14px;
  font-style: normal;
  font-weight: $g-bold;
  line-height: 24px;
  color: $textPrimary;
  white-space: nowrap;
  cursor: pointer;
}

.children,
.children * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.children.showMore,
.children.showMore * {
  text-overflow: unset;
  white-space: unset;
}
