.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  color: $textPrimary;

  @media ($smUp) {
    height: 100%;
  }

  a& {
    cursor: pointer;
  }

  @media ($xsDown) {
    padding: 0;
  }
}

.image {
  overflow: hidden;

  img {
    width: 100%;
  }
}

.subtitle {
  color: $secondary10;
  text-transform: uppercase;
}

.content {
  margin: 16px 0;
}

.text {
  font-size: 14px;
  line-height: 130%;
  color: $g-greyLuke;

  a {
    color: $white;

    &:hover {
      text-decoration: underline;
    }
  }
}

.button {
  margin-top: auto;
}
