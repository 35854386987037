.root {
  position: relative;
  color: $white;

  @media ($xsDown) {
    padding: 0;
  }

  &.fullHeight {
    height: 100vh;
    height: calc(100 * var(--vh));
    padding-top: $g-navHeight;
    margin-top: -$g-navHeight;
  }

  &.dynamicHeight {
    height: auto;
    padding: 50px 0;

    &.hasBackground {
      padding: 170px 0;
    }

    @media ($smDown) {
      padding: 40px 0;
    }
  }

  &.setHeightLarge {
    height: 500px;

    @media ($xsDown) {
      height: auto;
      min-height: 250px;
      padding: 40px 0;
    }
  }

  &.setHeightMedium {
    height: 370px;

    @media ($xsDown) {
      height: auto;
      min-height: 250px;
      padding: 30px 0;
    }
  }

  &.setHeightSmall {
    height: 300px;

    @media ($xsDown) {
      height: auto;
      min-height: 250px;
      padding: 20px 0;
    }
  }

  &.adaptHeight {
    height: auto;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $primary30;
  filter: brightness(0.8);
  background-position: 50% 60%;
  background-size: cover;
}

.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
