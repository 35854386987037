.root {
  z-index: $g-z-index-Nav;
  order: 2;
}

.containerDesktop {
  background-color: $grey10;

  @media ($smDown) {
    display: none;
  }
}

.desktopSteps {
  display: flex;
  width: 100%;
  height: $g-bookingStepsHeight;
  padding: 0;
  margin: 0;

  > * + * {
    margin-left: 40px;
  }
}

.step {
  display: flex;
  align-self: center;
  text-align: center;
  list-style: none;
}

.stepInner {
  display: flex;
  justify-content: center;
  padding: 8px 8px;
  font-size: 14px;
  font-weight: $g-semiBold;
  color: $white;
  opacity: 0.5;

  > div {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.current {
  font-weight: $g-default;
  cursor: default;

  .stepInner {
    color: $white;
    opacity: 1;
  }
}

.stepTextCurrent {
  text-decoration: underline;
}

.disabled {
  opacity: 0.4;

  * {
    cursor: not-allowed;
  }
}

.circle {
  $size: 20px;
  position: relative;
  display: inline-block;
  width: $size;
  height: $size;
  background-color: $white;
  border-radius: 50%;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  * {
    font-size: 14px;
    color: $grey10;
  }

  svg {
    $svgSize: 12px;
    width: $svgSize;
    height: $svgSize;
  }

  &.activeStep {
    * {
      color: $grey10;
    }
  }
}

.mobileSteps {
  display: none;
  align-items: center;
  justify-content: space-between;
  height: $g-bookingStepsHeightMobile;
  padding: 0 18px;
  font-size: 16px;
  font-weight: $g-bold;
  color: $white;
  background-color: $grey10;
  border-bottom: none;

  @media ($smDown) {
    display: flex;
  }

  @media ($xsDown) {
    overflow: hidden;
    font-size: 14px;

    & > div > span {
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.chevronCircle {
  $size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  margin-right: 10px;
  background-color: $white;
  border-radius: 50%;
}

.backButtonText {
  /** hide text for extremely small devices */

  @media (max-width: 300px) {
    display: none;
  }
}

.chevron {
  font-size: 10px;
  color: $grey10;
  composes: icon from "../../main/css/icon.module.css";
}
