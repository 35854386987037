.tabContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 8px;
  overflow: hidden;
  border-bottom: 2px solid $secondary10;
}

.tab {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  margin-bottom: -1px;
  font-size: 16px;
  font-weight: $g-bold;
  color: $textPrimary;
  background-color: $white;
  border: 1px solid $g-greyR2;

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  &[aria-pressed="true"] {
    color: $white;
    background-color: $secondary10;
  }

  &:first-child {
    border-top-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
  }
}

.accordion {
  width: 100%;

  @media ($xsDown) {
    border-bottom: 1px solid $grey30;
  }
}

.accordionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 18px 20px;
  font-size: 15px;
  font-weight: $g-bold;
  color: $g-red;
  text-align: center;

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  svg {
    color: $grey10;
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }

  &[aria-pressed="true"] svg {
    transform: scaleY(-1);
  }

  &.disallowAccordion {
    padding-bottom: 0;

    @media ($xsDown) {
      display: none;
      pointer-events: none;

      svg {
        display: none;
      }
    }
  }

  @media ($xsUp) {
    display: none;
  }
}

.accordionContent {
  display: none;
  padding: 24px 60px;

  @media ($xsDown) {
    padding: 8px 5px 60px 5px;
  }

  &.visible {
    display: block;
  }

  &.alwaysVisibleOnPhone {
    @media ($xsDown) {
      display: block;
    }
  }
}
